
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { Doughnut } from "vue-chartjs/legacy";
import { Chart as ChartJS, Title, Tooltip, ArcElement, Legend, CategoryScale, LinearScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

@Component({
    components: {
        Doughnut,
    },
})
export default class WonLostChart extends Vue {
    @Prop({ default: "" }) chartId!: string;
    @Prop({ default: "" }) datasetIdKey!: string;
    @Prop({ default: "" }) cssClasses!: string;
    @Prop({ default: () => {} }) styles!: object;
    @Prop({ default: () => {} }) plugins!: object;
    @Prop({ default: () => [] }) data!: number[];
    @Prop({ default: "#1BCFA9" }) wonColor!: string;
    @Prop({ default: "#FF6347" }) lostColor!: string;
    @Ref()
    chart: any;

    width: number = 300;
    height: number = 150;

    chartOptions = {};

    get chartData() {
        return {
            datasets: [
                {
                    backgroundColor: [this.wonColor, this.lostColor],
                    data: this.data,
                    circumference: 180,
                    rotation: 270,
                    offset: 12,
                },
            ],
        };
    }
}
